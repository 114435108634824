<template>
    <div>
        This is the redirect to the main Page
    </div>
</template>
<script>
export default {
    name: "Main",
}
</script>
<style lang="scsss" scoped>

</style>
